/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REQUIRED REACT FUNCTIONS */
import React, { useState, useEffect, useRef } from "react";

/* IMPORT BOOTSTRAP FOR PREDEFINED COMPONENTS */
import { Button, Card } from "react-bootstrap";

/* IMPORT MOTION FOR TAB SWITCHER */
import { motion } from "framer-motion";

/* IMPORT PHONE NUMBER IMPORT */
import PhoneInput from "react-phone-number-input";

/* IMPORT REACT-REDUX FOR GLOBAL STATE MANGAEMENT */
import { useDispatch, useSelector } from "react-redux";

/* IMPORT FOMRIK, YUP FOR FORM SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* IMPORT TOASTER FOR NOTIFICATION */
import { toast, ToastContainer } from "react-toastify";

import { AGENT_ID } from "../../config"

/* IMPORT COMBOBOX FOR ADDRESS-AUTOCOMPLETE */
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

/* IMPORT GOOGLE AUTOCOMPLETE  */
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

/* API IMPORTS */
import { homeEvaluationNew, updateHomeEvaluationNewById } from "../../service/api";

/* ACTIONS */
import actions from "../../redux/actions/userAction";

/* ICONS */
import UilAngleRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import UilAngleLeft from "@iconscout/react-unicons/icons/uil-arrow-left";

/* VALIDATION SCHEMA */
const addressValidation = yup.object().shape({
  streetAddress: yup.string().required("Address is required"),
  city: yup.string().required("Address is required"),
  state: yup.string().required("Address is required"),
  pinCode: yup.string().required("Address is required"),
  country: yup.string().required("Address is required"),
});

/* EMAIL ADDRESS STEP_TWO */
const personalInformationValidation = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  phone: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
});

const HomeEvaluationPage = () => {

  /* INITIALIZE USE-DISPATCH FOR REDUX ACTION */
  const dispatch = useDispatch();

  /* GET GLOBAL STATE  */
  const leadDetails = useSelector((state) => state?.user?.leadDetails)


  /* INITIALIZE SET-STATE */
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);

  const [thankYouPage, setThankYouPage] = useState(false);

  const [loading, setLoading] = useState(false);

  const [panAddressValues, setPanAddressValues] = useState([]);

  const [locationError, setLocationError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  /* FORM VALUES HANDLING */
  const ADDRESS_FORMIK = useFormik({
    initialValues: {
      streetAddress: "",
      city: "",
      state: "",
      pinCode: "",
      country: "",
      addedForAgent: AGENT_ID
    },
    validationSchema: addressValidation,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      console.log(values)
      homeEvaluationNew(values)
        .then((res) => {
          if (res?.status) {
            dispatch(actions.setLeadID(res?.data))
            setLocationError(false)
            setStepThree(!stepThree);
            setStepOne(!stepOne);
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          if (e?.response?.data?.message) {
            setLoading(false);
          }
        })
        .finally((res) => {
          setLoading(false);
          resetForm();
        });
    },
  });

  const PERSONAL_INFORMATION_FORMIK = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetAddress: leadDetails?.streetAddress,
      city: leadDetails?.city,
      state: leadDetails?.state,
      pinCode: leadDetails?.pinCode,
      country: leadDetails?.country,
      addedForAgent: AGENT_ID
    },
    validationSchema: personalInformationValidation,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("streetAddress", leadDetails?.streetAddress);
      formData.append("city", leadDetails?.city);
      formData.append("state", leadDetails?.state);
      formData.append("pinCode", leadDetails?.pinCode);
      formData.append("country", leadDetails?.country);
      formData.append("addedForAgent", AGENT_ID);

      if (values?.email !== "") {
        formData.append("email", values?.email);
      }
      if (values?.phone !== "") {
        formData.append("phone", values?.phone);
      }

      if (values?.firstName !== "") {
        formData.append("firstName", values?.firstName);
      }
      if (values?.lastName !== "") {
        formData.append("lastName", values?.lastName);
      }
      updateHomeEvaluationNewById(leadDetails?._id, formData)
        .then((res) => {
          if (res?.status) {
            dispatch(actions.setLeadID(null))
            setEmailError(false)
            setThankYouPage(!thankYouPage);
            setStepThree(!stepThree);
            // toast.success(res?.message);
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          if (e?.response?.data?.message) {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
          resetForm();
        });
    },
  });

  const panTo = React.useCallback(({ lat, lng, city, state, country, sTaddress, pinCode }) => {

    const address = sTaddress + ", " + city + ", " + state + ", " + pinCode + " - " + country
    setPanAddressValues({
      streetAddress: sTaddress,
      city,
      country,
      state,
      pinCode
    })
    setLocationError(false)
  }, []);

  useEffect(() => {
    if (panAddressValues?.streetAddress) {
      ADDRESS_FORMIK.setFieldValue("streetAddress", panAddressValues?.streetAddress)
      ADDRESS_FORMIK.setFieldValue("city", panAddressValues?.city)
      ADDRESS_FORMIK.setFieldValue("country", panAddressValues?.country)
      ADDRESS_FORMIK.setFieldValue("state", panAddressValues?.state)
      ADDRESS_FORMIK.setFieldValue("pinCode", panAddressValues?.pinCode)
      ADDRESS_FORMIK.setFieldError("streetAddress", "")
      ADDRESS_FORMIK.setFieldError("city", "")
      ADDRESS_FORMIK.setFieldError("country", "")
      ADDRESS_FORMIK.setFieldError("state", "")
      ADDRESS_FORMIK.setFieldError("pinCode", "")
      ADDRESS_FORMIK.setFieldTouched("streetAddress")
      setLocationError(false)
    } else {

    }
  }, [panAddressValues])
  return (
    <>
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="home-evaluation-page"
      >

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-5 col-sm-12">
              <Card className="form-card">
                <Card.Body className="form-cardbody">
                  {/* STEP 1 */}
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 },
                    }}
                    className={`step-1 ${stepOne ? "d-block" : "d-none"}`}
                  >
                    <form onSubmit={ADDRESS_FORMIK.handleSubmit}>
                      <h1>WHAT'S MY HOME WORTH ?</h1>
                      <h5>Enter your address to get your market report.</h5>
                      <div className="box-form">
                        <div className="form-group">
                          <label className="form-label">
                            Home Address <span>*</span>
                          </label>
                          <Search panTo={panTo} />

                          {locationError ? (
                            <small style={{ fontSize: "12px", color: "red" }}>
                              *Address is Required.
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex justify-content-end">
                          <Button onClick={() => {
                            ADDRESS_FORMIK.values.streetAddress !== '' ? setLocationError(false) : setLocationError(true)
                          }} disabled={loading} className="get-started-btn" type="submit">
                            {loading ? "Please wait..." : <span>Get Started <UilAngleRight color="#FFF" size="16" /></span>}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </motion.div>

                  {/* STEP 3 */}
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 },
                    }}
                    className={`step-3 ${stepThree ? "d-block" : "d-none"}`}
                  >
                    <h1>WHAT'S MY HOME WORTH ?</h1>
                    <h5>
                      Please provide your phone number and name below to get
                      your market report.
                    </h5>
                    <form onSubmit={PERSONAL_INFORMATION_FORMIK.handleSubmit}>
                      <div className="box-form">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                placeholder="First Name"
                                value={
                                  PERSONAL_INFORMATION_FORMIK.values?.firstName
                                }
                                onChange={
                                  PERSONAL_INFORMATION_FORMIK.handleChange
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                placeholder="Last Name"
                                value={
                                  PERSONAL_INFORMATION_FORMIK.values?.lastName
                                }
                                onChange={
                                  PERSONAL_INFORMATION_FORMIK.handleChange
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 col-lg-12 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email Address <span>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email address"
                                value={
                                  PERSONAL_INFORMATION_FORMIK.values?.email
                                }
                                onChange={
                                  PERSONAL_INFORMATION_FORMIK.handleChange
                                }
                              />

                              {PERSONAL_INFORMATION_FORMIK.errors.email ? (
                                <small
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  *Email Address is Required.
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-xl-12 col-lg-12 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Phone <span>*</span>
                              </label>
                              <PhoneInput
                                placeholder="Phone Number"
                                defaultCountry="US"
                                className="phone-number-select"
                                value={PERSONAL_INFORMATION_FORMIK.values.phone}
                                onChange={(phone) =>
                                  PERSONAL_INFORMATION_FORMIK.setFieldValue(
                                    "phone",
                                    phone
                                  )
                                }
                              />
                              {PERSONAL_INFORMATION_FORMIK.errors.phone ? (
                                <small
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  *Phone Number is Required.
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-buttons">
                          <Button
                            className="back-btn"
                            onClick={() => {
                              // setStepThree(!stepThree);
                              // setStepOne(!stepOne);
                              window.location.reload()
                            }}
                          >
                            <UilAngleLeft color="#323232" size="16" />
                            Previous
                          </Button>
                          <Button className="get-started-btn" type="submit">
                            Submit Details
                          </Button>
                        </div>
                      </div>
                    </form>
                  </motion.div>

                  {/* THANK YOU PAGE */}
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 },
                    }}
                    className={`thank-you-page ${thankYouPage ? "d-block" : "d-none"
                      }`}
                  >
                    <h1>
                      Your home value report is being prepared and will be
                      delivered shortly.
                    </h1>
                    <h5>
                      Your personalized home value report should be arriving in
                      your email within the next few minutes. Kindly check your
                      inbox, and don't forget to also check your spam folder.
                    </h5>
                    <div className="agent-profile">
                      <div className="profile-img">
                        <img
                          src={require("../../assets/creola-jefferson-profile.webp")}
                          className=""
                          alt="Creola Jefferson"
                        />
                      </div>
                      <div className="profile-details">
                        <h5>Creola Jefferson</h5>
                        <p>DRE #01890466</p>
                        <p>
                          Email:{" "}
                          <a href="mailto:crejefferson@gmail.com">
                            crejefferson@gmail.com
                          </a>
                        </p>
                        <p>
                          Phone: <a href="tel:13104188375">(310) 418-8375</a>
                        </p>
                      </div>
                    </div>
                  </motion.div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
};

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
      componentRestrictions: { country: 'US' }
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      const AddressComponent = results?.[0]?.address_components;

      console.log(results?.[0]?.address_components)
      let country = await (AddressComponent?.find((type) => type?.types?.[0] === 'country').short_name)
      let state = null
      let city = null
      let sTaddress = null
      let pinCode = null

      if (AddressComponent?.find((type) => type?.types?.[0] === 'street_number') !== undefined && AddressComponent?.find((type) => type?.types?.[0] === 'route') !== undefined) {
        sTaddress = AddressComponent?.find((type) => type?.types?.[0] === "street_number").long_name + " " + AddressComponent?.find((type) => type?.types?.[0] === 'route').long_name
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === 'administrative_area_level_1')) {
        state = await (AddressComponent?.find((type) => type?.types?.[0] === 'administrative_area_level_1').short_name)
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === 'locality')) {
        city = await (AddressComponent?.find((type) => type?.types?.[0] === 'locality').long_name)
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === "postal_code")) {
        pinCode = await (AddressComponent?.find((type) => type?.types?.[0] === "postal_code").long_name)
      }



      // if (AddressComponent?.find((type) => type?.types?.[0] === 'locality') === undefined) {
      //   city = AddressComponent?.find((type) => type?.types?.[0] === 'locality').long_name
      // }

      panTo({ lat, lng, city, state, country, sTaddress, pinCode });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="search w-100">
      <Combobox onSelect={handleSelect} className="search-combobox">
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          className="form-control"
          placeholder="Enter your home address"
        />

        <ComboboxPopover className="combobbox-popover-styles">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default HomeEvaluationPage;