const Footer = () => {
  return (
    <footer className="footer-3">
      <div className="container">
        <div className="row footer-main">
          <div className="col-xl-12 col-sm-12">
            <h4 className="agent-name">AMBERWOOD REAL ESTATE INC.</h4>
          </div>
          <div className="col-xl-12 col-sm-12">
            <div className="agent-details">
              <div className="details-box">
                <h5>CREOLA JEFFERSON</h5>
                <p>DRE #01890466</p>
              </div>
              <div className="details-box">
                <h5>EMAIL ADDRESS</h5>
                <a href="mailto:crejefferson@gmail.com">
                  crejefferson@gmail.com
                </a>
              </div>
              <div className="details-box">
                <h5>PHONE</h5>
                <a href="tel:+13104188375">(310) 418-8375</a>
              </div>
              <div className="details-box">
                <h5>ADDRESS</h5>
                <a
                  href="https://goo.gl/maps/dGB7bR1pBhzRCYjX6"
                  target="_blank"
                  rel="noreferrer"
                >
                  11060 Artesia Blvd Suite G, Cerritos, CA 90703
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-sm-12">
            <p className="footer-small-info">
              Creola Jefferson is a real estate agent licensed by the state of
              California affiliated with Amberwood Real Estate. Creola Jefferson
              is a real estate agent licensed by the state of California and
              abides by equal housing opportunity laws. All material presented
              herein is intended for informational purposes only. Information is
              compiled from sources deemed reliable but is subject to errors,
              omissions, changes in price, condition, sale, or withdrawal
              without notice. No statement is made as to accuracy of any
              description. All measurements and square footages are approximate.
              This is not intended to solicit property already listed. Nothing
              herein shall be construed as legal, accounting or other
              professional advice outside the realm of real estate brokerage.
            </p>
          </div>
        </div>
        <div className="copyright-main">
          <h5>
            © 2024, CREOLA JEFFERSON. POWERED BY{" "}
            <a
              href="https://www.roundtechsquare.com"
              target="_blank"
              rel="noreferrer"
            >
              ROUNDTECHSQUARE
            </a>
          </h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
